import React from 'react';
 
export default (state, action) => {
   switch(action.type) {
       case 'ADD_ITEM':
           return {
                   shoppingList: [action.payload, ...state.shoppingList]
           }
       case 'REMOVE_ITEM':
           return {
               shoppingList: state.shoppingList.filter(item => item !== action.payload)
           }

           case 'SAFE_CURRENT_GLOBAL_STATE':
               console.log(action)
               let entries = Object.entries(action.payload)
               let previousState = state.shoppingList
               previousState[entries[0][0]] = entries[0][1]
               console.log()
            return {
                    shoppingList: previousState
            }
       default:
           return state;
   }
}