import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);
 
    // Actions for changing state
 
     function addItemToList(item) {
        dispatch({
            type: 'ADD_ITEM',
            payload: item
        });
    }
    function removeItemFromList(item) {
        dispatch({
            type: 'REMOVE_ITEM',
            payload: item
        });
    }
    function saveCurrentState(item) {
        dispatch({
            type: 'SAFE_CURRENT_GLOBAL_STATE',
            payload: item
        });
    }
 
    return(
       <GlobalContext.Provider value = {{shoppingList : state.shoppingList, addItemToList, removeItemFromList, saveCurrentState}}> 
         {children} 
    </GlobalContext.Provider>
    )
 }

const initialState = {
    
   shoppingList : {LastPrice: "", CurrentJobAttributes: {}, VerificationStatus: "", Duration: "2.5", ratingFriendliness: 5, OnPoint: 5, OnTime: 5, HelperDetails:{}, VendorDetails:{}, RepetitionAmount: 2, Repetition: "single", vendorAllowedConcurrentJobs: 1, vendorLiveJobs: 0, Gender: "female"  } ,
   addItemToList: GlobalProvider.addItemToList,
   saveCurrentState: GlobalProvider.saveCurrentState,
}

export const GlobalContext = createContext(initialState);



