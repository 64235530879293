import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonContent,
  IonFooter,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
/* import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form'; */
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle, home, ribbon, shield, calendar, logIn, search } from 'ionicons/icons';
import CookieConsent from "react-cookie-consent";

import ReactGA from "react-ga4";
import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module'

import { AuthService } from './services/graphQlService';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
/* import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css'; */

import "./pages/tab8.css"
import "./pages/Tab1.css"

import './theme/variables.css';
import './components/ProfileForm.css';

import Auth from '@aws-amplify/auth';

/* import App from './AppV'; */
/* 
import Start from './Start';
import AuthentificatorNew from './authentificatorNew'; */

/* import JobFormPublic from './components/JobFormPublic';

import LandingBaselHelper from './pages/LandingBaselHelper';
import LandingBaselPutzfrau from './pages/LandingBaselPutzfrau';
import Datenschutz from './pages/Datenschutz';
import AGB from './pages/AGB';
import Mwst from './pages/Mwst';
import Tab11 from './pages/Tab11';
import ImpressumN from './pages/ImpressumN';
import DirectLogin from './pages/DirectLogin';
import Unsubscribe from './pages/Unsubscribe'; */
import { GlobalProvider } from './context/globalState';
import Tab7 from './pages/Tab7';

import loadable from '@loadable/component' 
import Wartung from './pages/TabWartung';


const App = loadable(() => import('./AppV')) 
const DirectLogin = loadable(() => import('./pages/DirectLogin')) 
const Start = loadable(() => import('./Start')) 
const AuthentificatorNew = loadable(() => import('./authentificatorNew')) 

const JobFormPublic = loadable(() => import('./components/JobFormPublic')) 
const Tab11 = loadable(() => import('./pages/Tab11')) 
/* const GlobalProvider = loadable(() => import('./context/globalState'))  */

/* const Tab7 = loadable(() => import('./pages/Tab7')) */


const LandingBaselHelper = loadable(() => import('./pages/LandingBaselHelper')) 
const LandingBaselPutzfrau = loadable(() => import('./pages/LandingBaselPutzfrau')) 
const ImpressumN = loadable(() => import('./pages/ImpressumN')) 
const Datenschutz = loadable(() => import('./pages/Datenschutz')) 
const AGB = loadable(() => import('./pages/AGB')) 
const Mwst = loadable(() => import('./pages/Mwst')) 
const Unsubscribe = loadable(() => import('./pages/Unsubscribe')) 






/* Theme variables */
interface props {
  mode?:string
}
let window:any
const Publicpages: React.FC<props> = (props?) => {
  

  const tagManagerArgs = {
    gtmId: 'GTM-W4F398L'
  }
  TagManager.initialize(tagManagerArgs)
 /*  TagManager.dataLayer({
    dataLayer: {
      event: 'PublicPages',
      pagePath: '/',
      pageTitle: 'Sauberness - Startseite',
    },
  }); */
    
    ReactGA.initialize("G-C8W8FE1N1L");
   

   console.log('Publicpages loaded')
   console.log(props)
  useEffect(() => {

    uid()
    /* console.log('isBrowser: '+isBrowser)   */
   
    
    
  })
   async function  uid() {
     let uid1 = await AuthService()
     console.log(uid1)
     render(<AuthentificatorNew mode="vendor"/>, document.getElementById('root'))
     /* if(uid1){
      render(<AppHelper uid={uid1} />, document.getElementById('root'))
     } */
   }
   async function  vendorLoggedIn() {
    let uid1 = await AuthService()
    if(uid1){
     render(<App uid={uid1} />, document.getElementById('root'))
    }
  }
   if(props.mode && props.mode == 'helper'){
    uid()
   }
   if(props.mode && props.mode == 'vendor'){
    vendorLoggedIn()
   }
   function getStarted(){
    render(<AuthentificatorNew mode="vendor"/>, document.getElementById('root'))
  }
  
   
  return(
    <GlobalProvider>
    {/* <HubspotProvider> */}
  
  
  <IonApp /* style={{maxHeight:"87%"}} */ >
  
    <IonReactRouter>
      <IonTabs  >

        <IonRouterOutlet>
       
        <Route exact path="/">
        <Wartung />
          </Route>
          {/* <Route exact path="/suche-putzjobs">
            <Tab6 />
          </Route> */}
          <Route exact path="/partner">
            <LandingBaselHelper />
          </Route>
          <Route exact path="/putzjobs-basel">
            <LandingBaselHelper />
          </Route>
          <Route exact path="/impressum">
            <ImpressumN />
          </Route>
          <Route exact path="/datenschutz">
            <Datenschutz />
          </Route>
          <Route exact path="/mwst-information">
            <Mwst />
          </Route>
          <Route exact path="/rules/privacy">
            <Datenschutz />
          </Route>
          <Route exact path="/unsubscribe">
            <Unsubscribe />
          </Route>
         {/*  <Route exact path="/putzen-job">
            <Anmeldung />
          </Route> */}
          <Route exact path="/start">
            <Start mode="vendor"/>
          </Route>
          <Route exact path="/wartung">
            <Wartung />
          </Route>
          <Route exact path="/agb">
            {/* <TabAgb /> */}
            <AGB/>
          </Route>
          <Route exact path="/putzfrau-finden">
            <JobFormPublic />
          </Route>
        {/*   <Route exact path="/putzhilfe-basel">
            <LandingBasel />
          </Route> */}
          <Route exact path="/putzfrau-basel">
            <LandingBaselPutzfrau />
          </Route>
          <Route exact path="/login">
            <DirectLogin/>
          </Route>
         
          <Route exact path="/app">
            <Tab11 />
            
          </Route>
          
          
          <Route exact path="/ta*">
            <Redirect to="/" />
          </Route>
          <Route exact path="/profil">
          <Redirect to="/login" />
          </Route>
          <Route exact path="/logout">
          <Redirect to="/login" />
          </Route>
          <Route exact path="/helfer-finden">
            <Redirect to="/start" />
          </Route>
          <Route exact path="/meine-termine">
            <Redirect to="/start" />
          </Route>
          <Route exact path="/meine-termine">
            <Redirect to="/start" />
          </Route>
          <Route exact path="/offene-termine">
            <Redirect to="/start" />
          </Route>
   

         
        </IonRouterOutlet>

        <IonTabBar slot="bottom">
          {/* <IonTabButton tab="tab8" href="/">
            <IonIcon icon={triangle} />
            <IonLabel>Start</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab7" href="/putzhilfe-gesucht">
            <IonIcon icon={square} />
            <IonLabel>Putzhilfe gesucht</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab6" href="/suche-putzjobs">
            <IonIcon icon={ellipse} />
            <IonLabel>Putzjobs finden</IonLabel>
          </IonTabButton> */}
           <IonTabButton tab="wartung" href="/wartung">
            <IonIcon icon={home} />
            <IonLabel>Wartung</IonLabel>
            </IonTabButton>
          {/*  <IonTabButton tab="home" href="/">
            <IonIcon icon={home} />
            <IonLabel>Start</IonLabel>
          </IonTabButton>
           <IonTabButton tab="putzhilfe-basel" href="/putzfrau-basel">
            <IonIcon icon={search} />
            <IonLabel>Putzhilfe Basel</IonLabel>
          </IonTabButton>
           <IonTabButton tab="login" href="/login">
            <IonIcon icon={logIn} />
            <IonLabel>Direktlogin</IonLabel>
          </IonTabButton>
          <IonTabButton tab="app" href="/app">
            <IonIcon icon={ribbon} />
            <IonLabel>Über Sauberness</IonLabel>
          </IonTabButton>
          <IonTabButton tab="impressum" href="/impressum">
            <IonIcon icon={shield} />
            <IonLabel>Impressum</IonLabel>
          </IonTabButton> */}
         
       
      
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>

    <CookieConsent
  location="bottom"
  buttonText="Akzeptieren"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
  debug={false}
  /* onAccept={acceptCookies()} */
>
  Diese Website verwendet Cookies um die Nutzererfahrung zu verbessern.{" "}
  <span style={{ fontSize: "10px" }}><a href='/datenschutz' target='_blank' >Datenschutzerklärung</a></span>
</CookieConsent>



  </IonApp>
  
  {/* </HubspotProvider> */}
  </GlobalProvider>)
};

export default Publicpages
