/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTimeEstimationFeedbackHelper = /* GraphQL */ `
  query GetTimeEstimationFeedbackHelper($id: ID!) {
    getTimeEstimationFeedbackHelper(id: $id) {
      id
      ip
      country
      selectedSize
      timeguessresult
      upvote
      selectedHours
      payresult
      uid
      ProfileFeedbackQuestionnaire
      createdAt
      updatedAt
    }
  }
`;
export const listTimeEstimationFeedbackHelpers = /* GraphQL */ `
  query ListTimeEstimationFeedbackHelpers(
    $filter: ModelTimeEstimationFeedbackHelperFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeEstimationFeedbackHelpers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ip
        country
        selectedSize
        timeguessresult
        upvote
        selectedHours
        payresult
        uid
        ProfileFeedbackQuestionnaire
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      uidCreator
      Price
      Duration
      Rooms
      Size
      Time
      Date
      Putzen
      Ort
      Street
      Status
      PreferredGender
      VendorRating
      HelperRating
      Description
      meta
      CurrentHelper
      CurrentHelperApply
      HelperCanceled
      Hausnummer
      Country
      zip
      lastnameVendor
      firstnameVendor
      vendorProfileUrl
      helperProfileUrl
      Secret
      LastHelperName
      LastHelperFirstName
      TimeStatus
      PreferredHelpers
      createdAt
      updatedAt
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uidCreator
        Price
        Duration
        Rooms
        Size
        Time
        Date
        Putzen
        Ort
        Street
        Status
        PreferredGender
        VendorRating
        HelperRating
        Description
        meta
        CurrentHelper
        
        HelperCanceled
        Hausnummer
        Country
        zip
        lastnameVendor
        firstnameVendor
        vendorProfileUrl
        helperProfileUrl
        Secret
        LastHelperName
        LastHelperFirstName
        TimeStatus
        PreferredHelpers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listJobsAnonym = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uidCreator
        Price
        Duration
        Rooms
        Size
        Time
        Date
        Putzen
        Ort
        Status
        VendorRating
        HelperRating
        Description
        meta
        CurrentHelper
        HelperCanceled
        Country
        PreferredGender
        zip
        vendorProfileUrl
        helperProfileUrl
        LastHelperName
        LastHelperFirstName
        TimeStatus
        PreferredHelpers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendors = /* GraphQL */ `
  query GetVendors($id: ID!) {
    getVendors(id: $id) {
      id
      vendorAllowedConcurrentJobs
      vendorLiveJobs
      uid
      LastRooms
      LastSize
      PreferredGender
      LastDate
      LastTime
      Gender
      LastPrice
      LastStreet
      LastZIP
      LastDesc
      PreferredHelpers
      meta
      LastCountry
      LastOrt
      LastDuration
      LastHausNummer
      lastname
      email
      phone
      OverallRating
      ratingExpectations
      ratingFriendliness
      ratingHassleFreeExecution
      jobsCreated
      jobsCanceld
      JobsSuccessfulExecuted
      NoShowsVendor
      firstname
      Credits
      VerificationStatus
      LastOnline
      createdAt
      updatedAt
    }
  }
`;
export const listVendors = /* GraphQL */ `
  query ListVendors(
    $filter: ModelVendorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vendorAllowedConcurrentJobs
        vendorLiveJobs
        uid
        LastRooms
        LastSize
        PreferredGender
        LastDate
        LastTime
        Gender
        LastPrice
        LastStreet
        LastZIP
        LastDesc
        PreferredHelpers
        meta
        LastCountry
        LastOrt
        LastDuration
        LastHausNummer
        lastname
        email
        phone
        OverallRating
        ratingExpectations
        ratingFriendliness
        ratingHassleFreeExecution
        jobsCreated
        jobsCanceld
        JobsSuccessfulExecuted
        NoShowsVendor
        firstname
        Credits
        VerificationStatus
        LastOnline
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHelper = /* GraphQL */ `
  query GetHelper($id: ID!) {
    getHelper(id: $id) {
      id
      firstname
      lastname
      email
      phone
      gender
      LastDate
      LastTime
      LastStreet
      VerifikationStatus
      PLZAreas
      LastHausNummer
      LastOrt
      LastZIP
      LastCountry
      PreferredVendors
      meta
      uid
      PreferredWeekDays
      PreferredTimes
      ServicingArea
      MethodOfTravel
      Tools
      Questionnaire
      Detergerants
      JobsAccepted
      JobsSuccessfulExecuted
      NoShows
      OnTime
      OnPoint
      ratingFriendliness
      OverallRating
      JobsCanceled
      Credits
      VerificationStatus
      LastOnline
      createdAt
      updatedAt
    }
  }
`;
export const listHelpers = /* GraphQL */ `
  query ListHelpers(
    $filter: ModelHelperFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHelpers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        email
        phone
        gender
        LastDate
        LastTime
        LastStreet
        VerifikationStatus
        PLZAreas
        LastHausNummer
        LastOrt
        LastZIP
        LastCountry
        PreferredVendors
        meta
        uid
        PreferredWeekDays
        PreferredTimes
        ServicingArea
        MethodOfTravel
        Tools
        Questionnaire
        Detergerants
        JobsAccepted
        JobsSuccessfulExecuted
        NoShows
        OnTime
        OnPoint
        ratingFriendliness
        OverallRating
        JobsCanceled
        Credits
        VerificationStatus
        LastOnline
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRatings = /* GraphQL */ `
  query GetRatings($id: ID!) {
    getRatings(id: $id) {
      id
      uidVendor
      uidHelper
      CurrentTotalRating
      ratingExpectationsOfVendor
      ratingFriendlinessOfVendor
      ratingHassleFreeExecution
      jobExecutedByHelper
      blockhelper
      OnTime
      OnPoint
      ratingFriendlinessOfHelper
      jobCompensatedByVendor
      messageVendor
      messageHelper
      FeedbackVendor
      FeedbackHelper
      ratingByVendor
      ratingVendorTagCloud
      ratingByHelper
      ratingHelperTagCloud
      HelperGotExtraCredit
      VendorGotExtraCredit
      NegativeRatingMarker
      PreferredHelper
      createdAt
      updatedAt
    }
  }
`;
export const listRatings = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uidVendor
        uidHelper
        CurrentTotalRating
        ratingExpectationsOfVendor
        ratingFriendlinessOfVendor
        ratingHassleFreeExecution
        jobExecutedByHelper
        blockhelper
        OnTime
        OnPoint
        ratingFriendlinessOfHelper
        jobCompensatedByVendor
        messageVendor
        messageHelper
        FeedbackVendor
        FeedbackHelper
        ratingByVendor
        ratingVendorTagCloud
        ratingByHelper
        ratingHelperTagCloud
        HelperGotExtraCredit
        VendorGotExtraCredit
        NegativeRatingMarker
        PreferredHelper
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
