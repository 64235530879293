import API, { graphqlOperation } from '@aws-amplify/api';
import { createHelper, createJob, createVendors, updateVendors, addJob } from '../graphql/mutations';
import { getHelper, getRatings, getVendors, listJobs, listJobsAnonym, listRatings, listVendors } from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { renderIntoDocument } from 'react-dom/test-utils';

import Snackbar from '@mui/material/Snackbar';
import { hydrate, render } from 'react-dom';
import Auth from '@aws-amplify/auth';



  export const FetchOpenJobsAnonym = async (gender:string) => {
    console.log(gender)
    try {
      const todoData:any = await API.graphql(graphqlOperation(listJobsAnonym, {filter:{Status: {contains: "open"}, 	PreferredGender: {eq: gender}}, limit: 1000}))
      /* let toDoData2Length = 0
      if(todoData.data.listJobs.items.length > 99){
        const todoData2:any = await API.graphql(graphqlOperation(listJobs,  {nextToken: todoData.data.listJobs.nextToken, filter:{Status: {contains: "open"}}}))
        let toDoData2Length = todoData2.data.listJobs.items.length
      } */
      
      return todoData
      
    } catch (err) { console.log('error fetching jobs')
    console.log(err)
  }
  }

  export const FetchMyJobsVendor = async (uid:string) => {
    try {
      const todoData = await API.graphql(graphqlOperation(listJobsAnonym, {filter:{uidCreator: {contains: uid}}}))
      
      return todoData
      
    } catch (err) { console.log('error fetching jobs', JSON.stringify(err)) }
  }

  export const FetchMyJobsVendorAmount = async (uid:string) => {
    try {
      const todoData1:any = await API.graphql(graphqlOperation(listJobsAnonym, {filter:{uidCreator: {contains: uid}}}))
      
      let toDoData2Length = 0
      if(todoData1.data.listJobs.items.length > 99){
        const todoData2:any = await API.graphql(graphqlOperation(listJobsAnonym,  {nextToken: todoData1.data.listJobsAnonym.nextToken, filter:{uidCreator: {contains: uid}}}))
        let toDoData2Length = todoData2.data.listJobs.items.length
      }
      
      const todoData = todoData1.data.listJobs.items.length+toDoData2Length
      
      return todoData
      
    } catch (err) { console.log('error fetching jobs') }
  }

  export const FetchMyJobsVendorCanceled = async (uid:string) => {
    try {
      const todoData = await API.graphql(graphqlOperation(listJobsAnonym, {filter:{uidCreator: {contains: uid}, Status: {contains: 'canceled'}}}))
      console.log(todoData)
      return todoData
      
    } catch (err) { console.log('error fetching jobs') }
  }

  export const FetchMyJobsVendorConfirmedPast = async (uid:string) => {
    try {
      console.log(uid)
      const todoData = await API.graphql(graphqlOperation(listRatings, {filter:{uidVendor: {contains: uid}, jobCompensatedByVendor: {eq: true}}}))
      console.log(todoData)
      return todoData
      
    } catch (err) { console.log('error fetching jobs') }
  }

  export const FetchMyJobsHelper = async (uid:string) => {
    try {
      const todoData = await API.graphql(graphqlOperation(listJobs, {filter:{CurrentHelper: {contains: uid}}}))
      console.log(todoData)
      return todoData
      
    } catch (err) { console.log('error fetching jobs') }
  }

  export const FetchMyJobsHelperPast = async (uid:string) => {
    try {
      const todoData = await API.graphql(graphqlOperation(listJobs, {filter:{CurrentHelper: {contains: uid}, TimeStatus: {contains: "past"}}}))
      console.log(todoData)
      return todoData
      
    } catch (err) { console.log('error fetching jobs') }
  }
  export const FetchMyJobsHelperRated = async (uid:string) => {
    try {
      const todoData = await API.graphql(graphqlOperation(listJobsAnonym, {filter:{CurrentHelper: {contains: uid}, TimeStatus: {contains: "rated"}}}))
      console.log(todoData)
      return todoData
      
    } catch (err) { console.log('error fetching jobs') }
  }

  export const WriteVerifikationStatus = async (uid:string) => {
    console.log(uid)
    try {
     let slot = {id: uid, "VerifikationStatus":"userUpload"}
      await API.graphql(graphqlOperation(mutations.updateHelper, {input: slot}))
    } catch (err) {
      console.log('error creating vendor:', err)
    }
  }

  export const FetchMyJobsHelperCanceled = async (uid:string) => {
    try {
      const todoData = await API.graphql(graphqlOperation(listJobsAnonym, {filter:{CurrentHelper: {contains: uid}, Status: {contains: "canceled"}}}))
      /* console.log(todoData) */
      return todoData
      
    } catch (err) { console.log('error fetching jobs') }
  }

  export const FetchMyJobsHelperConfirmed = async (uid:string) => {
    try {
      const todoData = await API.graphql(graphqlOperation(listJobs, {filter:{CurrentHelper: {contains: uid}, Status: {contains: "confirmed"}, TimeStatus: {attributeExists: false}}}))
      /* console.log(todoData) */
      return todoData
      
    } catch (err) { console.log('error fetching jobs', err) }
  }
  
  export const AddJob = async (obj:any) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = obj
     let jobString = JSON.stringify(job)
     console.log(jobString)
      let success = await API.graphql(graphqlOperation(addJob, {event:jobString}))
      return success
    } catch (err) {
      console.log('error creating job:', err)
    }
  }

  export const FetchVendors = async () => {
    try {
      const todoData = await API.graphql(graphqlOperation(listVendors))
      return todoData
      
    } catch (err) { console.log('error fetching jobs') }
  }

  export const FetchVendor = async (id:string) => {
    try {
      const todoData = await API.graphql(graphqlOperation(getVendors, {id: id}))
      return todoData
      
    } catch (err) { console.log('error fetching jobs', err) }
  }

  export const FetchHelper = async (id:string) => {
    try {
      const todoData = await API.graphql(graphqlOperation(getHelper, {id: id}))
      return todoData
      
    } catch (err) { console.log('error fetching jobs', err) }
  }
  
  export const AddVendor = async (uid:string, email:string) =>  {
    try {
     let job = {"id": uid, "uid": uid, "email":email}
      await API.graphql(graphqlOperation(createVendors, {input: job}))
    } catch (err) {
      console.log('error creating vendor:', err)
    }
  }

  export const AddProfileFeedbackQuestionnaire = async (uid:string, feedbackString:string) =>  {
    let ranNo = Math.round(Math.random()*100)
    try {
     let slot = {"id": uid+ranNo, "uid": uid, "ProfileFeedbackQuestionnaire":feedbackString}
      await API.graphql(graphqlOperation(mutations.createTimeEstimationFeedbackHelper, {input: slot}))
    } catch (err) {
      console.log('error creating vendor:', err)
    }
  }

  export const AddTimeEstimatorHelperFeedback = async (dataObject: any) =>  {
    try {
      let stringigiedObject = JSON.stringify(dataObject)
      /* await API.graphql(graphqlOperation(mutations.feedbackTimeEstimator, {event: stringigiedObject})) */
      await API.graphql( {query: mutations.createTimeEstimationFeedbackHelper,
        variables: {input: dataObject},
        authMode: 'API_KEY'})
        /* const result = await client.mutate({
          mutation: gql(mutations.feedbackTimeEstimator),
          variables: {
            input: {
              name: 'Use AppSync',
              description: 'Realtime and Offline',
            }
          }
        }); */
     
    } catch (err) {
      console.log('error creating vendor:', err)
    }
  }
  

  export const AddHelper = async (uid:string, email?:string) =>  {
    try {
     let job = {"id": uid,"uid": uid, "email":email, "Credits": 2, OnTime: 5, OnPoint: 5, ratingFriendliness: 5}
      await API.graphql(graphqlOperation(createHelper, {input: job}))
    } catch (err) {
      console.log('error creating job:', err)
    }
  }

  export const UpdateVendor = async (uid:string, vendor:any) =>  {
      
    try {
     vendor.id = uid
     /* vendor.uid = uid */
     vendor.LastRooms = Number(vendor.LastRooms)
     vendor.LastSize = Number(vendor.LastSize)
     if(vendor.Updating){
      delete vendor['Updating']
     }
     vendor.LastZIP = Number(vendor.LastZIP)
     if(vendor.LastHausNummer && vendor.LastStreet){
        vendor.meta = JSON.stringify({validated : true})
     }
     console.log(vendor)
     /* const keysArray = Object.keys(vendor)
     const objectLength = keysArray.length
     console.log(objectLength) */
     /* let variables = {input: vendor} */
     let variablesString = JSON.stringify(vendor)
      await API.graphql(graphqlOperation(mutations.updateAfterInit,  {event: variablesString}))
      /* await API.graphql( { query: mutations.updateVendors, variables: {input: {id: "123456331234532", lastname: "schoo"}}}) */
      return {errors: false}
     
    } catch (err:any) {

      console.log('error update vendor:', err)
      return err
    }
  }
  export const UpdateVendorOld = async (uid:string, vendor:any) =>  {
      
    try {
     vendor.id = uid
     /* vendor.uid = uid */
     vendor.LastRooms = Number(vendor.LastRooms)
     vendor.LastSize = Number(vendor.LastSize)
     if(vendor.Updating){
      delete vendor['Updating']
     }
     vendor.LastZIP = Number(vendor.LastZIP)
     if(vendor.LastHausNummer && vendor.LastStreet){
        vendor.meta = JSON.stringify({validated : true})
     }
     console.log(vendor)
     /* const keysArray = Object.keys(vendor)
     const objectLength = keysArray.length
     console.log(objectLength) */
     let variables = {input: vendor}
      await API.graphql(graphqlOperation(updateVendors,  variables))
      /* await API.graphql( { query: mutations.updateVendors, variables: {input: {id: "123456331234532", lastname: "schoo"}}}) */
      return {errors: false}
     
    } catch (err:any) {

      console.log('error update vendor:', err)
      return err
    }
  }

  export const UpdateVendorLean = async (uid:string, input:any) =>  {
      
    try {
     let finalInput = input
     finalInput['id'] = uid
     let variables = {input: finalInput}
     console.log(variables)
      await API.graphql(graphqlOperation(updateVendors,  variables))
      /* await API.graphql( { query: mutations.updateVendors, variables: {input: {id: "123456331234532", lastname: "schoo"}}}) */
      return {errors: false}
     
    } catch (err:any) {

      console.log('error upating vendor lean:', err.errors[0].message)
      return err
    }
  }

  export const UpdateHelper = async (uid:string, vendor:any) =>  {
      
    try {
     vendor.id = uid
     vendor.LastZIP = Number(vendor.LastZIP)
      vendor.LastHausNummer = String(vendor.LastHausNummer)
    
     console.log(vendor)
     
     /* let variables = {input: vendor} */
      /* await API.graphql(graphqlOperation(mutations.updateHelper,  variables)) */
      let variablesString = JSON.stringify(vendor)
      await API.graphql(graphqlOperation(mutations.updateAfterInitHelper,  {event: variablesString}))
      return {errors: false}
     
    } catch (err:any) {

      console.log('error creating job:', err.errors[0].message)
      return err
    }
  }

  export const UpdateHelperLean = async (uid:string, obj:any) =>  {
      
    try {
     obj.id = uid
    
     
     let variables = {input: obj}
      await API.graphql(graphqlOperation(mutations.updateHelper,  variables))
      
      return {errors: false}
     
    } catch (err:any) {

      console.log('error creating job:', err.errors[0].message)
      return err
    }
  }

  export const AcceptJob = async (helperUid:string, jobId:any, helperDetails:any) =>  {
    let firstname = {LastHelperFirstName: helperDetails.firstname}
    let lastname = {LastHelperName: helperDetails.lastname}
    try {
     let object = {id: jobId, CurrentHelperApply: helperUid}
     let stringifiedObject = JSON.stringify(object)
     /* let variables = {input: object} */
      /* await API.graphql(graphqlOperation(mutations.updateJobHelper,  variables)) */
      let success = await API.graphql(graphqlOperation(mutations.acceptJobHelper, {event:stringifiedObject}))
      /* await API.graphql( { query: mutations.updateVendors, variables: {input: {id: "123456331234532", lastname: "schoo"}}}) */
      return {errors: false}
     
    } catch (err:any) {

      console.log('error confirming job:', err.errors[0].message)
      return err
    }
  }

  export const HideJob = async (jobId:any) =>  {
      
    try {
     let object = {id: jobId, Status: "canceled"}
      let stringifiedObject = JSON.stringify(object)
     /* let variables = {input: object} */
      /* await API.graphql(graphqlOperation(mutations.updateJob,  variables)) */
      let success = await API.graphql(graphqlOperation(mutations.cancelJobVendor, {event:stringifiedObject}))
      /* await API.graphql( { query: mutations.updateVendors, variables: {input: {id: "123456331234532", lastname: "schoo"}}}) */
      return {errors: false}
     
    } catch (err:any) {

      console.log('error confirming job:', err.errors[0].message)
      return err
    }
  }

  export const AuthService = async () => {
    const uid = Auth.currentAuthenticatedUser().then(e => {
        console.log(e)
        return e.username
    })
    return uid
  }

  export const CancelJob = async (helperUid:string, jobId:any, HelperCanceled:any) =>  {
    let previousHelpers = []
    if(HelperCanceled){
         previousHelpers = JSON.parse(HelperCanceled)
    }
    console.log(HelperCanceled)
    
    previousHelpers.push(helperUid)
      
      console.log(previousHelpers)
      
      let previousHelpersString = JSON.stringify(previousHelpers)
    try {
     let object = {id: jobId, CurrentHelper: helperUid}
     let stringifiedObject = JSON.stringify(object)
     let variables = {input: object}
      /* await API.graphql(graphqlOperation(mutations.cancelJobCurrentHelper,  variables)) */
      let success = await API.graphql(graphqlOperation(mutations.cancelJobHelper, {event:stringifiedObject}))
      /* await API.graphql( { query: mutations.updateVendors, variables: {input: {id: "123456331234532", lastname: "schoo"}}}) */
      return success
     
    } catch (err:any) {

      console.log('error confirming job:', err.errors[0].message)
      return err
    }
  }

  export const AddRating = async (obj:any) =>  {
      console.log(obj)
    try {
     let variables = obj
     let input = {input: variables}
      await API.graphql(graphqlOperation(mutations.createRatings, input))
    } catch (err) {
      console.log('error Adding Rating:', err)
    }
  }

  export const UpdateRating = async (obj:any) =>  {
    console.log(obj)
  try {
   let variables = obj
   let input = {input: variables}
    await API.graphql(graphqlOperation(mutations.updateRatings, input))
  } catch (err) {
    console.log('error Adding Rating:', err)
  }
}

  export const AddAsPreferredHelper = async (uidHelper:any, vendorId:any) =>  {
    
    let finalHelperArray = await FetchVendor(vendorId).then((e:any) => {
      console.log(e)
      let preferredHelpers = e.data.getVendors.PreferredHelpers
      let preferredHelpersArray = []
      if(preferredHelpers){
        preferredHelpersArray = JSON.parse(preferredHelpers)
        if(preferredHelpersArray.indexOf(uidHelper) == -1){
          preferredHelpersArray.push(uidHelper)
        }
        
      } else {
        preferredHelpersArray.push(uidHelper)
      }

      return JSON.stringify(preferredHelpersArray)

    })
  try {
   let variables = {id: vendorId, PreferredHelpers: finalHelperArray}
   let input = {input: variables}
    await API.graphql(graphqlOperation(mutations.updateVendors, input))
  } catch (err) {
    console.log('error creating job:', err)
  }
}
  
export const GetRating = async (id:string) => {
    try {
      const todoData = await API.graphql(graphqlOperation(getRatings, {id: id}))
      return todoData
      
    } catch (err) { console.log('error fetching jobs', err) }
  }

  export const FetchRatingsHelper = async (uid:any) => {
    try {
        const todoData = await API.graphql(graphqlOperation(listRatings, {filter:{uidHelper: {contains: uid}}}))
        console.log(todoData)
        return todoData
        
      } catch (err) { console.log('error fetching ratings helper') }
    }

    export const FetchRatingsVendor = async (uid:any) => {
        try {
            const todoData = await API.graphql(graphqlOperation(listRatings, {filter:{uidVendor: {contains: uid}}}))
            console.log(todoData)
            return todoData
            
          } catch (err) { console.log('error fetching ratings vendors') }
        }

    export const CalculateRatingHelper = (allRatings:any) => {
        let OnTime = 0
        let OnPoint = 0
        let ratingFriendlinessOfHelper = 0
        let noShows = 0
        let jobExecutedByHelper = 0
        let amountRatings = allRatings.length

        for(let el of allRatings){
            if(el.jobExecutedByHelper === false){
                noShows = noShows+1
            }
            if(el.jobExecutedByHelper === true){
                jobExecutedByHelper = jobExecutedByHelper+1
            }
            OnTime = OnTime+el.OnTime
            OnPoint = OnPoint+el.OnPoint
            ratingFriendlinessOfHelper = ratingFriendlinessOfHelper+el.ratingFriendlinessOfHelper
        }
        let averageOnTime = Math.round(OnTime/amountRatings)
        let averageOnPoint = Math.round(OnPoint/amountRatings)
        let averageratingFriendlinessOfHelper = Math.round(ratingFriendlinessOfHelper/amountRatings)
        
        return {OnTime: averageOnTime, OnPoint:averageOnPoint, ratingFriendlinessOfHelper:averageratingFriendlinessOfHelper, NoShows: noShows, JobsSuccessfulExecuted:jobExecutedByHelper}
    }

    export const CalculateRatingVendor = (allRatings:any) => {
        let ratingExpectationsOfVendor = 0
        let ratingFriendlinessOfVendor = 0
        let ratingHassleFreeExecution = 0
        let jobExecutedByHelper = 0
        
        let amountRatings = allRatings.length

        for(let el of allRatings){
            if(el.jobExecutedByHelper === true){
                jobExecutedByHelper = jobExecutedByHelper+1
            }
            ratingExpectationsOfVendor = ratingExpectationsOfVendor+el.ratingExpectationsOfVendor
            ratingFriendlinessOfVendor = ratingFriendlinessOfVendor+el.ratingFriendlinessOfVendor
            ratingHassleFreeExecution = ratingHassleFreeExecution+el.ratingHassleFreeExecution
        }
        let averageratingExpectationsOfVendor = Math.round(ratingExpectationsOfVendor/amountRatings)
        let averageratingFriendlinessOfVendor = Math.round(ratingFriendlinessOfVendor/amountRatings)
        let averageratingHassleFreeExecution = Math.round(ratingHassleFreeExecution/amountRatings)
        
        return {ratingExpectationsOfVendor: averageratingExpectationsOfVendor, ratingFriendlinessOfVendor:averageratingFriendlinessOfVendor, ratingHassleFreeExecution:averageratingHassleFreeExecution, jobExecutedByHelper: jobExecutedByHelper}
    }

   

  

  