import { IonButton, IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonHeader, IonItem, IonList, IonPage, IonSearchbar, IonText, IonTitle, IonToolbar } from '@ionic/react';



import React, { useEffect, useRef, useState } from 'react';

/* import "./tab8.css"
import './Tab1.css'; */

import ReactGA from "react-ga4";

import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { Image } from '@aws-amplify/ui-react';


interface props {
  mode:string
}
let window:any

const Wartung: React.FC = () => {

  const [value, setValue] = useState('');
  const [available, setavailable] = useState(false);
  const inputRef = useRef<any>(null);
  const searchButtonRef = useRef<any>(null);
  const history = useHistory()

useEffect(()=> {
  /* const tagManagerArgs = {
    dataLayer: {
      event: 'pageview',
      pagePath: 'sauberness.com/',
      pageTitle: 'Start',
      visitorType: 'servicesuchender'
    },
    dataLayerName: 'PageDataLayer'
}
TagManager.dataLayer(tagManagerArgs) */
/* ReactGA.send({ hitType: "pageview", page: "/", pageTitle: "Startseite" }); */
ReactGA.event({
  category: "vendor",
  action: "Startseite",
});
})

const onClick = React.useCallback(() => {
  inputRef.current.focus();
  let plz = Number(inputRef.current.value)
  if(plz > 3999 && plz < 4128){
    alert(`Deine Region ist verfügbar ${inputRef.current.value}`);
    setavailable(true)
  } else{
    alert(`Deine Region ist noch nicht verfügbar ${inputRef.current.value}`);
  }
  
}, []);

useEffect(() => {
  if (searchButtonRef && searchButtonRef.current) {
    // Note: this example is contrived to demonstrate using refs.
    // Use the `onSubmit` prop on `SearchField` instead which
    // responds to input field `Enter` keypresses and Submit button clicks.
    searchButtonRef.current.addEventListener('click', onClick, false);
    /* return () => {
      searchButtonRef.current.removeEventListener('click', onClick, false);
    }; */
  }
 
}, [onClick]);



  
  /* cs() */
  function getStarted(){
    /* render(<AuthentificatorNew mode="vendor"/>) */
    history.push('/putzfrau-finden')
  }
  function searchText(e:any){
    if(e.length >= 4){
      let plz = Number(e)
    if(e > 3999){
      if(plz < 4128){
      
        alert(`Deine Region ist verfügbar ${e}`);
        setavailable(true)
      } else{
        alert(`Deine Region ist noch nicht verfügbar ${e}`);
      } 
    } else{
      alert(`Deine Region ist noch nicht verfügbar ${e}`);
    } 
    }
    
   
    
    /* setValue(e)
    let plz = Number(e)
    
    if(plz > 3999 && plz < 4128){
      
      alert(`Deine Region ist verfügbar ${inputRef.current.value}`);
      setavailable(true)
    } else{
      alert(`Deine Region ist noch nicht verfügbar ${inputRef.current.value}`);
    } */
  }

 /*  window.dataLayer.push({
    event: 'pageview'
  }); */
  
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
         
        <IonTitle style={{height:80}}> 
        <Image
                      style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -45%)',
                    }}
                      alt="Sauberness logo"
                      height={100}
                      src="https://saubernessbucket121805-dev.s3.eu-central-1.amazonaws.com/public/sauberness-logo-blue-wht.png"
                    />
    </IonTitle>
        </IonToolbar>
      </IonHeader>
      
      <IonContent fullscreen>
      
        <IonHeader collapse="condense">
        <Helmet>
                      
                      <meta property="og:title" content="Sauberness - Putzhilfe on-demand. Jetzt ausprobieren und Putztermin planen" />  
                       <meta name="description" content="Geprüfte Putzkräfte. Ab 29 CHF - keine Vertragslaufzeiten" />       
                      
                      </Helmet>
          <IonToolbar  >
         
          <img
                      style={{ display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      width: "100px"}}
                      alt="Sauberness logo"
                      className="center"
                      
                      src="https://saubernessbucket121805-dev.s3.eu-central-1.amazonaws.com/public/sauberness-logo-blue-wht.png"
                    />
                    
         
            {/* <IonTitle style={{textAlign:"center"}} size="large">Sauberness</IonTitle> */}
          </IonToolbar>
        </IonHeader>
        <IonCard>
        {/* <IonItem> */}
          {/* <IonCardContent> */}
          {/* <img src="https://saubernessbucket121805-dev.s3.eu-central-1.amazonaws.com/public/sauberness-start.png"></img> */}
          <img src="https://saubernessbucket121805-dev.s3.eu-central-1.amazonaws.com/public/cleanerInApartment.webp"></img>
          {/* </IonCardContent> */}
             
            {/* </IonItem> */}
            <IonCardContent>
        <IonCardTitle>
           Service nicht verfügbar
          </IonCardTitle>
          <IonCardSubtitle>
           Kein Service verfügbar 
          </IonCardSubtitle>
        </IonCardContent>
          <IonCardContent>
             {/* (K)<strong>einen</strong> Plan für ein sauberes Zuhause? <br/><br/> */}
            Das Projekt wurde eingestellt.
            
     
          </IonCardContent>
          {/* <SaubernessBenefits /> */}
       {/* Wir glauben an Fairness, Innovation & Flexibilität. */}
        {/* <IonCardContent>
          
          Die bevorzugten Einstellungen werden in der App gespeichert und ermöglichen die Erstellung von einem neuen Putzeinsatz in unter 60 Sekunden. Jetzt den Terminplaner entdecken!
          </IonCardContent> */}
      
       
          <IonCardContent class="ion-align-items-center">
            {available && <IonButton color="success" onClick={()=> getStarted()}>Weiter</IonButton>}
            {!available && <IonButton disabled >Jetzt loslegen</IonButton>}
           
           
          </IonCardContent>
        </IonCard>
       
       
      </IonContent>
    </IonPage>
  );
};

export default Wartung;
