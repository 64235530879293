/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addJob = /* GraphQL */ `
  mutation AddJob($event: String) {
    addJob(event: $event)
  }
`;
export const cancelJobVendor = /* GraphQL */ `
  mutation CancelJobVendor($event: String) {
    cancelJobVendor(event: $event)
  }
`;
export const cancelJobHelper = /* GraphQL */ `
  mutation CancelJobHelper($event: String) {
    cancelJobHelper(event: $event)
  }
`;
export const acceptJobHelper = /* GraphQL */ `
  mutation AcceptJobHelper($event: String) {
    acceptJobHelper(event: $event)
  }
`;
export const updateAfterInit = /* GraphQL */ `
  mutation UpdateAfterInit($event: String) {
    updateAfterInit(event: $event)
  }
`;
export const updateAfterInitHelper = /* GraphQL */ `
  mutation UpdateAfterInitHelper($event: String) {
    updateAfterInitHelper(event: $event)
  }
`;
export const feedbackTimeEstimator = /* GraphQL */ `
  mutation FeedbackTimeEstimator($event: String) {
    feedbackTimeEstimator(event: $event)
  }
`;
export const createTimeEstimationFeedbackHelper = /* GraphQL */ `
  mutation CreateTimeEstimationFeedbackHelper(
    $input: CreateTimeEstimationFeedbackHelperInput!
    $condition: ModelTimeEstimationFeedbackHelperConditionInput
  ) {
    createTimeEstimationFeedbackHelper(input: $input, condition: $condition) {
      id
      ip
      country
      selectedSize
      timeguessresult
      upvote
      selectedHours
      payresult
      uid
      ProfileFeedbackQuestionnaire
      createdAt
      updatedAt
    }
  }
`;
export const updateTimeEstimationFeedbackHelper = /* GraphQL */ `
  mutation UpdateTimeEstimationFeedbackHelper(
    $input: UpdateTimeEstimationFeedbackHelperInput!
    $condition: ModelTimeEstimationFeedbackHelperConditionInput
  ) {
    updateTimeEstimationFeedbackHelper(input: $input, condition: $condition) {
      id
      ip
      country
      selectedSize
      timeguessresult
      upvote
      selectedHours
      payresult
      uid
      ProfileFeedbackQuestionnaire
      createdAt
      updatedAt
    }
  }
`;
export const deleteTimeEstimationFeedbackHelper = /* GraphQL */ `
  mutation DeleteTimeEstimationFeedbackHelper(
    $input: DeleteTimeEstimationFeedbackHelperInput!
    $condition: ModelTimeEstimationFeedbackHelperConditionInput
  ) {
    deleteTimeEstimationFeedbackHelper(input: $input, condition: $condition) {
      id
      ip
      country
      selectedSize
      timeguessresult
      upvote
      selectedHours
      payresult
      uid
      ProfileFeedbackQuestionnaire
      createdAt
      updatedAt
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      uidCreator
      Price
      Duration
      Rooms
      Size
      Time
      Date
      Putzen
      Ort
      Street
      Status
      PreferredGender
      VendorRating
      HelperRating
      Description
      meta
      CurrentHelper
      CurrentHelperApply
      HelperCanceled
      Hausnummer
      Country
      zip
      lastnameVendor
      firstnameVendor
      vendorProfileUrl
      helperProfileUrl
      Secret
      LastHelperName
      LastHelperFirstName
      TimeStatus
      PreferredHelpers
      createdAt
      updatedAt
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      uidCreator
      Price
      Duration
      Rooms
      Size
      Time
      Date
      Putzen
      Ort
      Street
      Status
      PreferredGender
      VendorRating
      HelperRating
      Description
      meta
      CurrentHelper
      CurrentHelperApply
      HelperCanceled
      Hausnummer
      Country
      zip
      lastnameVendor
      firstnameVendor
      vendorProfileUrl
      helperProfileUrl
      Secret
      LastHelperName
      LastHelperFirstName
      TimeStatus
      PreferredHelpers
      createdAt
      updatedAt
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      uidCreator
      Price
      Duration
      Rooms
      Size
      Time
      Date
      Putzen
      Ort
      Street
      Status
      PreferredGender
      VendorRating
      HelperRating
      Description
      meta
      CurrentHelper
      CurrentHelperApply
      HelperCanceled
      Hausnummer
      Country
      zip
      lastnameVendor
      firstnameVendor
      vendorProfileUrl
      helperProfileUrl
      Secret
      LastHelperName
      LastHelperFirstName
      TimeStatus
      PreferredHelpers
      createdAt
      updatedAt
    }
  }
`;
export const createVendors = /* GraphQL */ `
  mutation CreateVendors(
    $input: CreateVendorsInput!
    $condition: ModelVendorsConditionInput
  ) {
    createVendors(input: $input, condition: $condition) {
      id
      vendorAllowedConcurrentJobs
      vendorLiveJobs
      uid
      LastRooms
      LastSize
      PreferredGender
      LastDate
      LastTime
      Gender
      LastPrice
      LastStreet
      LastZIP
      LastDesc
      PreferredHelpers
      meta
      LastCountry
      LastOrt
      LastDuration
      LastHausNummer
      lastname
      email
      phone
      OverallRating
      ratingExpectations
      ratingFriendliness
      ratingHassleFreeExecution
      jobsCreated
      jobsCanceld
      JobsSuccessfulExecuted
      NoShowsVendor
      firstname
      accounttype
      Credits
      VerificationStatus
      LastOnline
      createdAt
      updatedAt
    }
  }
`;
export const updateVendors = /* GraphQL */ `
  mutation UpdateVendors(
    $input: UpdateVendorsInput!
    $condition: ModelVendorsConditionInput
  ) {
    updateVendors(input: $input, condition: $condition) {
      id
      vendorAllowedConcurrentJobs
      vendorLiveJobs
      uid
      LastRooms
      LastSize
      PreferredGender
      LastDate
      LastTime
      LastPrice
      LastStreet
      LastZIP
      LastDesc
      PreferredHelpers
      meta
      LastCountry
      LastOrt
      LastDuration
      LastHausNummer
      lastname
      email
      phone
      OverallRating
      ratingExpectations
      ratingFriendliness
      ratingHassleFreeExecution
      jobsCreated
      jobsCanceld
      JobsSuccessfulExecuted
      NoShowsVendor
      firstname
      accounttype
      Credits
      VerificationStatus
      LastOnline
      createdAt
      updatedAt
    }
  }
`;
export const deleteVendors = /* GraphQL */ `
  mutation DeleteVendors(
    $input: DeleteVendorsInput!
    $condition: ModelVendorsConditionInput
  ) {
    deleteVendors(input: $input, condition: $condition) {
      id
      vendorAllowedConcurrentJobs
      vendorLiveJobs
      uid
      LastRooms
      LastSize
      PreferredGender
      LastDate
      LastTime
      Gender
      LastPrice
      LastStreet
      LastZIP
      LastDesc
      PreferredHelpers
      meta
      LastCountry
      LastOrt
      LastDuration
      LastHausNummer
      lastname
      email
      phone
      OverallRating
      ratingExpectations
      ratingFriendliness
      ratingHassleFreeExecution
      jobsCreated
      jobsCanceld
      JobsSuccessfulExecuted
      NoShowsVendor
      firstname
      accounttype
      Credits
      VerificationStatus
      LastOnline
      createdAt
      updatedAt
    }
  }
`;
export const createHelper = /* GraphQL */ `
  mutation CreateHelper(
    $input: CreateHelperInput!
    $condition: ModelHelperConditionInput
  ) {
    createHelper(input: $input, condition: $condition) {
      id
      firstname
      accounttype
      lastname
      email
      phone
      gender
      LastDate
      LastTime
      LastStreet
      VerifikationStatus
      PLZAreas
      LastHausNummer
      LastOrt
      LastZIP
      LastCountry
      PreferredVendors
      meta
      uid
      PreferredWeekDays
      PreferredTimes
      ServicingArea
      MethodOfTravel
      Tools
      Questionnaire
      Detergerants
      JobsAccepted
      JobsSuccessfulExecuted
      NoShows
      OnTime
      OnPoint
      ratingFriendliness
      OverallRating
      JobsCanceled
      Credits
      VerificationStatus
      LastOnline
      createdAt
      updatedAt
    }
  }
`;
export const updateHelper = /* GraphQL */ `
  mutation UpdateHelper(
    $input: UpdateHelperInput!
    $condition: ModelHelperConditionInput
  ) {
    updateHelper(input: $input, condition: $condition) {
      id
      firstname
      accounttype
      lastname
      email
      phone
      LastDate
      LastTime
      LastStreet
      VerifikationStatus
      PLZAreas
      LastHausNummer
      LastOrt
      LastZIP
      LastCountry
      PreferredVendors
      meta
      uid
      PreferredWeekDays
      PreferredTimes
      ServicingArea
      MethodOfTravel
      Tools
      Questionnaire
      Detergerants
      JobsAccepted
      JobsSuccessfulExecuted
      NoShows
      OnTime
      OnPoint
      ratingFriendliness
      OverallRating
      JobsCanceled
      Credits
      VerificationStatus
      LastOnline
      createdAt
      updatedAt
    }
  }
`;
export const deleteHelper = /* GraphQL */ `
  mutation DeleteHelper(
    $input: DeleteHelperInput!
    $condition: ModelHelperConditionInput
  ) {
    deleteHelper(input: $input, condition: $condition) {
      id
      firstname
      accounttype
      lastname
      email
      phone
      gender
      LastDate
      LastTime
      LastStreet
      VerifikationStatus
      PLZAreas
      LastHausNummer
      LastOrt
      LastZIP
      LastCountry
      PreferredVendors
      meta
      uid
      PreferredWeekDays
      PreferredTimes
      ServicingArea
      MethodOfTravel
      Tools
      Questionnaire
      Detergerants
      JobsAccepted
      JobsSuccessfulExecuted
      NoShows
      OnTime
      OnPoint
      ratingFriendliness
      OverallRating
      JobsCanceled
      Credits
      VerificationStatus
      LastOnline
      createdAt
      updatedAt
    }
  }
`;
export const createRatings = /* GraphQL */ `
  mutation CreateRatings(
    $input: CreateRatingsInput!
    $condition: ModelRatingsConditionInput
  ) {
    createRatings(input: $input, condition: $condition) {
      id
      uidVendor
      uidHelper
      CurrentTotalRating
      meta
      ratingExpectationsOfVendor
      ratingFriendlinessOfVendor
      ratingHassleFreeExecution
      jobExecutedByHelper
      blockhelper
      OnTime
      OnPoint
      ratingFriendlinessOfHelper
      jobCompensatedByVendor
      messageVendor
      messageHelper
      FeedbackVendor
      FeedbackHelper
      ratingByVendor
      ratingVendorTagCloud
      ratingByHelper
      ratingHelperTagCloud
      HelperGotExtraCredit
      VendorGotExtraCredit
      NegativeRatingMarker
      PreferredHelper
      createdAt
      updatedAt
    }
  }
`;
export const updateRatings = /* GraphQL */ `
  mutation UpdateRatings(
    $input: UpdateRatingsInput!
    $condition: ModelRatingsConditionInput
  ) {
    updateRatings(input: $input, condition: $condition) {
      id
      uidVendor
      uidHelper
      CurrentTotalRating
      meta
      ratingExpectationsOfVendor
      ratingFriendlinessOfVendor
      ratingHassleFreeExecution
      jobExecutedByHelper
      blockhelper
      OnTime
      OnPoint
      ratingFriendlinessOfHelper
      jobCompensatedByVendor
      messageVendor
      messageHelper
      FeedbackVendor
      FeedbackHelper
      ratingByVendor
      ratingVendorTagCloud
      ratingByHelper
      ratingHelperTagCloud
      HelperGotExtraCredit
      VendorGotExtraCredit
      NegativeRatingMarker
      PreferredHelper
      createdAt
      updatedAt
    }
  }
`;
export const deleteRatings = /* GraphQL */ `
  mutation DeleteRatings(
    $input: DeleteRatingsInput!
    $condition: ModelRatingsConditionInput
  ) {
    deleteRatings(input: $input, condition: $condition) {
      id
      uidVendor
      uidHelper
      CurrentTotalRating
      meta
      ratingExpectationsOfVendor
      ratingFriendlinessOfVendor
      ratingHassleFreeExecution
      jobExecutedByHelper
      blockhelper
      OnTime
      OnPoint
      ratingFriendlinessOfHelper
      jobCompensatedByVendor
      messageVendor
      messageHelper
      FeedbackVendor
      FeedbackHelper
      ratingByVendor
      ratingVendorTagCloud
      ratingByHelper
      ratingHelperTagCloud
      HelperGotExtraCredit
      VendorGotExtraCredit
      NegativeRatingMarker
      PreferredHelper
      createdAt
      updatedAt
    }
  }
`;
