/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://mxhmhit2irefpg4aecz6m23pfy.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-44w3di6xv5ftneo2yxud7bxxka",
    "aws_cognito_identity_pool_id": "eu-central-1:dad3d347-b087-492c-9e96-4bdaad2a0027",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_jt8AKYMpM",
    "aws_user_pools_web_client_id": "vdbkgt94falgj9cno1al9rh1i",
    "oauth": {
        "domain": "sauberness.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://sauberness.com/start/",
        "redirectSignOut": "https://sauberness.com/logout/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "saubernessbucket121805-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
